/* .navigation-wrapper {
    max-width: 100vw;
    overflow: hidden;
    box-sizing: border-box;
}

.navigation-wrapper * {
    box-sizing: border-box;
} */

.break-small {
    display: none;
}

.inner-content-about .heading-wrapper {
    margin: 0;
}

.inner-content-about {
    min-height: 0;
}

.container-nav {
    justify-content: center;
}

.stake-crop-container {
    margin-bottom: 80px;
}

.web3modal-modal-lightbox {
    z-index: 9999 !important;
}

.button-coming-soon {
    pointer-events: none;
    position: relative;
    opacity: 0.8;
}

.btn-disabled {
    pointer-events: none !important;
    opacity: 0.5 !important;
}

.staking-warning {
    margin: 0 auto;
    max-width: 500px;
    padding: 40px;
    text-align: center;
}

.staking-selectors {
    max-height: calc(75vh - 50px);
    overflow-y: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
    margin: 15px;
}

.staking-selector img {
    max-width: 100%;
}

.staking-selector {
    position: relative;
    border: 2px solid #eee;
    cursor: pointer;
}

.staking-selector h5 {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
}

.staking-selector:hover {
    border-color: #2a5fbb;
}

.staking-selector.selected {
    border-color: red !important;
}

.staking-selector.selected:after {
    content: '✅';
    position: absolute;
    top: 10px;
    right: 10px;
}

.staking-controls {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.staking-controls .button {
    margin: 0 10px 15px;
}

.modal-tabs {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    border-bottom: 1px solid #ddd;
    width: 100%;
}

.modal-tabs li {
    text-align: center;
    margin: 0;
}

.staking-coins {
    position: absolute;
    top: 5px;
    left: 5px;
    /* border: 1px solid #ccc; */
    box-shadow: 0 0 10px 0 rgba(250, 120, 100, 0.55);
    border-radius: 999px;
    background: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    line-height: 15px;
    padding: 3px 5px;
    text-align: center;
}

.staking-coins:after {
    content: 'C';
    color: #fff;
    margin-left: 8px;
    background: #008850;
    display: inline-block;
    text-align: center;
    border-radius: 15px;
    font-size: 12px;
    line-height: 15px;
    height: 15px;
    width: 15px;
}

.modal-tabs li:not(:last-child) {
    border-right: 1px solid #ddd;
    background: #fff;
}

.modal-tabs li a {
    display: inline-block;
    padding: 16px 15px 6px;
}

.modal-tabs li.selected a {
    color: #ff00ae;
}

.staking-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 15px 15px;
    width: 100%;
}

.staking-footer .button {
    margin: 0;
}

.button-coming-soon:after {
    text-align: center;
    content: 'COMING SOON';
    position: absolute;
    display: flex;
    color: #000;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.75);
}

.social-links a {
    text-decoration: none;
    display: inline-block;
    margin: 4px 10px;
}

.social-links a:hover path {
    fill: #c00;
}

@media screen and (max-width: 767px) {
    .modal {
        width: 100%;
    }
    .staking-selectors {
        grid-template-columns: 1fr 1fr;
        gap: 8px;
        margin: 10px;
    }
    .hero-heading._2 {
        font-size: 28vw;
    }
    .stake-crop-container {
        margin-bottom: 0;
    }
    .break-small {
        display: block;
    }
    .section-2 .grid-4 {
        grid-template-columns: 1fr 1fr;
        padding: 0 15px;
    }
    .section-2 .main-heading {
        margin: 60px 40px 40px;
    }
}